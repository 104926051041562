.Menu{
    order: 1;
    margin-top:2vh;
    display: flex;
    margin-left: 2vw;
    flex-direction: row;
    overflow-x: scroll; 
    align-self: center; 
    z-index: 2;
}

.Menu::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 40.5em) {
    .Menu{
        order: 1;
        margin-top:2vh;
        display: flex;
        margin-left: 2vw;
        flex-direction: row;
        align-self: unset;
        overflow-x: scroll; 
    }
}