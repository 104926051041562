.Card{
    margin-top: 4em;
    margin-left: 4em;
    order: 2;
    z-index: 10;
    height: fit-content;
    padding-bottom: 5em;
    min-width: 20vw;
    background-color: #FAFAFA; /*#D9D9D9*/
    outline: 1px solid #5C5C5C;
    
}

.cardTitle{
    background-color: #5C5C5C;
    padding: 8px 0 8px 1rem;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
    color:#D9D9D9;
}

.information{
    max-width: 20em;
    padding: .5rem 1rem;
}

.left-right{
    max-width: 20em;
    padding: .5rem 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.left{
  
    font-size: .9rem;
    letter-spacing: 2px;
    font-weight: 500;
}
.right{
    
    font-size: .9rem;
    letter-spacing: 2px;
    font-weight: 500;
}

.containers{
    padding: 0 1rem;
}

.containers > hr{
   border-top: solid 2px rgba(0, 0, 0, 0.05);
}

.boxes{
    height: 15px;
    width: 15px;
    border:solid 2px rgba(0, 0, 0, 0.05);
    
}

.center{
    margin-left: auto;
    margin-right: auto;
}



@media screen and (max-width: 40.5em) {
    .Card{
        margin: 0px;
        width: 100vw;
    }
}
