.Body{
    width: 100vw;
    height: 100vh;
    background-color: #FAFAFA;
    background-size: 4px 4px;
    background-image: linear-gradient(90deg, transparent, transparent 3px, rgba(0,0,0,.07) 4px),linear-gradient(transparent, transparent 3px, rgba(0,0,0,.07) 4px);
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    
}



@media screen and (max-width: 40.5em) {
    .Body{
        background-color: #FAFAFA;
        background-size: 4px 4px;
        background-image: linear-gradient(90deg, transparent, transparent 3px, rgba(0,0,0,.07) 4px),linear-gradient(transparent, transparent 3px, rgba(0,0,0,.07) 4px);
    }
}