.Scanbar {
    position: absolute;
    margin: 0;
    z-index: 1;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.05);
    opacity: .295;
}


@media screen and (max-width: 40.5em) {

}