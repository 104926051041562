.Menu > .Buttons:not(:first-child){
    margin-left: 3vw;
}

.Buttons{
    display: inline-block;
    z-index: 11;
    min-width: 150px; 
    padding:4px;
}

.Buttons:hover{
    border-left: 0;
    border-right: 0 ;
    
}

hr{
    padding: 0;
    margin: 0;
}

.Group-Button{
    /*background-color: #D9D9D9;
    color: #5C5C5C;*/
    padding-top:5px;
    padding-bottom:5px;

}

/*
.Group-Button:hover{
    background-color: #5C5C5C;
    color: #D9D9D9;
}
*/

.Group-Button:hover > .Ico-Block{
    /*background-color: #D9D9D9;*/
    -webkit-animation-name: blink;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    
    -moz-animation-name: blink;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
    
    -ms-animation-name: blink;
    -ms-animation-duration: 1s;
    -ms-animation-iteration-count: infinite;
    
    -o-animation-name: blink;
    -o-animation-duration: 1s;
    -o-animation-iteration-count: infinite;
    
     animation-name: blink;
     animation-duration: 1s;
     animation-iteration-count: infinite;
}

@-webkit-keyframes blink {
    0% { background-color: whitesmoke; }
 }
 @-moz-keyframes blink {
    0% { background-color: whitesmoke; }
 }
 @keyframes blink {
    0% { background-color: whitesmoke; }
 }

.Ico-Block{
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-left: 5px;
    /*background-color:#5C5C5C;*/
}

.Ico-Block > img{
    display: inline;
}

.Button-Name{
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 5px;
    letter-spacing: 2px;
}

@media screen and (max-width: 40.5em) {
    .Buttons{
        border-bottom: solid 2px transparent;
    }
    
}