.Options{
    z-index: 10;
    width: 20em;
    
    margin-top: 4em;
    margin-left: 4em;
    order: 1;
    height: fit-content;
    
}

.Options > ul{
    margin-top: 0px;
    padding: 0px;
    width: 100%;
    
}
/*Single Line Options*/
ul > .Buttons{
    display: block;
}

@media screen and (max-width: 40.5em) {
    .Options{
        margin-top: 4em;
        margin-left: 0em;
        width:100%
    }

}