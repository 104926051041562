.Content{
    order: 2;
    height: 90vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.title{
    position: absolute;
    left: 4em;
}
.title > h1{
    line-height: 1;
    font-weight: 100;
    letter-spacing: 0.1em;
}

@media screen and (max-width: 40.5em) {
    .title{
        position: absolute;
        left: 0em;
    }
}

